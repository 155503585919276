import { BUTTON_QUERY, TEXT_BLOCK_QUERY, PRODUCT_QUERY, CONTENT_BLOCK_QUERY } from '@lib/fragments';

export const HERO_QUERY = `
... on HeroSection {
  __typename
  sys {
    id
  }
  image {
     url (transform: {width: 1280, format: JPG_PROGRESSIVE})
     description
  }
  imageDesktop {
     url (transform: {width: 2880, format: JPG_PROGRESSIVE})
     description
  }
  layout
  textBlockAlignment
  textBlock {
    ${TEXT_BLOCK_QUERY}
  }
  button {
    ${BUTTON_QUERY}
  }
}
`;

export const VALUE_PROPOSITION_QUERY = `
... on ValuePropositionSection {
  __typename
  sys {
    id
  }
  missionStatement
  textBlocksCollection(limit: 5) {
    items {
      ${TEXT_BLOCK_QUERY}
    }
  }
}
`;

export const REVIEW_QUERY = `
... on Review {
  sys {
    id
  }
  rating
  message {
    json
  }
  author
  image {
    url (transform: {format: JPG_PROGRESSIVE})    
    description
  }
}
`;

export const PAGE_SECTION_QUERY = `
... on PageSection {
  __typename
  sys {
    id
  }
  layout
  preTitle
  title
  backgroundColor {
    name 
    value
  }
  blocksCollection(limit: 8) {
    items {
      __typename
      ${CONTENT_BLOCK_QUERY}
      ${REVIEW_QUERY}
      ${PRODUCT_QUERY}
    }
  }
}
`;

export const PRODUCT_PAGE_SECTION_QUERY = `
... on PageSection {
  __typename
  sys {
    id
  }
  layout
  preTitle
  title
  backgroundColor {
    name 
    value
  }
  blocksCollection(limit: 4) {
    items {
      __typename
      ${CONTENT_BLOCK_QUERY}
      ${REVIEW_QUERY}
      ${PRODUCT_QUERY}
    }
  }
}
`;

export const TEXT_SECTION_QUERY = `
... on TextSection {
  __typename
  sys {
    id
  }
  preText
  textBlock {
   ${TEXT_BLOCK_QUERY}
  }
  button {
    ${BUTTON_QUERY}
  }
}
`;

export const CONTENT_SECTION_QUERY = `
  ... on ContentSection {
      __typename
    sys {
      id
    }
    backgroundColor {
      name
      value
    }
    alignImage
    image {
      url (transform: {format: JPG_PROGRESSIVE})      
      description
    }
    preText
    textBlock {
      ${TEXT_BLOCK_QUERY}
    }
    button {
      ${BUTTON_QUERY}
    }
  }
`;

export const PRODUCTS_SECTION_QUERY = `
  ... on ProductsSection {
    __typename
    sys {
      id
    }
    backgroundColor {
      name
      value
    }
    alignImage
    imageSize
    image {
      url (transform: {format: JPG_PROGRESSIVE})
      description
    }
    productsCollection(limit: 30) {
      items {
        __typename
        ${PRODUCT_QUERY}
      }
    }
  }
`;

export const BANNER_SECTION_QUERY = `
  ... on BannerSection {
      __typename
    sys {
      id
    }
    preTitle
    title
    image {
      url (transform: {format: JPG_PROGRESSIVE})
      description
    }
  }
`;
