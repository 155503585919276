import { sleep } from '@lib/utils';

const spaceId = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const environmentId = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID;
const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
const previewToken = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;

export async function fetchContent(query: string, preview?: boolean, attempt = 1): Promise<any> {
  // environmentId === 'master' ? 'https://hautehijab-ecomm.graphcdn.app' :
  const url = `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${environmentId}`;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${preview ? previewToken : accessToken}`,
    },
    body: JSON.stringify({ query }),
  });
  const { data, errors } = await res.json();
  errors && console.log('fetch error', errors);
  if (errors) {
    await sleep(attempt * 10);
    return fetchContent(query, preview, attempt + 1);
  }
  return data;
}
